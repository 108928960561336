'use client'
import {signIn, signOut} from 'next-auth/react'
import {useEffect} from 'react'
import {FORCE_LOGIN_PROMPT} from '~auth/fool-auth-utils'

const sanitizeReturnPath = (path: string) => {
  if (path.startsWith('/premium')) {
    return path
  }
  return `/premium${path.startsWith('/') ? '' : '/'}${path}`
}

export default function Signin() {
  useEffect(() => {
    ;(async () => await signOut)()

    const querystring = new URL(location.href).searchParams
    const returnPath = querystring.get('returnPath')
    const prompt = querystring.get('prompt') || FORCE_LOGIN_PROMPT
    const connection = querystring.get('connection')
    interface SignInOptions {
      prompt?: string
      connection?: string
    }

    const authOptions: SignInOptions = {
      prompt: prompt,
    }

    if (connection) {
      authOptions.connection = connection
    }

    signIn(
      'auth0',
      {
        callbackUrl: returnPath ? sanitizeReturnPath(returnPath) : '/premium',
      },
      authOptions,
    )
  }, [])
  return <></>
}
